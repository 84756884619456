"use client";

import clsx from "clsx";
import Modal from "@/components/Modal/Modal";
import React, { FC, useState } from "react";
import HeaderBurgerButton from "../../HeaderBurgerButton/HeaderBurgerButton";
import HeaderLogo from "../../HeaderLogo/HeaderLogo";
import { HeaderBuilderContent } from "../../headerTypes";
import styles from "./NavCorporate.module.scss";
import RegionWebsiteSelectorModal from "@/components/LocalWebsiteSelector/RegionSelectorModal";
import HeaderNavItems from "../HeaderNavItems/HeaderNavItems";

type Region = { title: string; link: string; browserLanguageCode: string };
interface NavProps {
	builderMenuContent: HeaderBuilderContent;
	openMobileMenu: boolean;
	setOpenMobileMenu: (state: boolean) => void;
	regionDropdowns: Region[];
}

const NavCorporate: FC<NavProps> = ({
	builderMenuContent,
	openMobileMenu,
	setOpenMobileMenu,
	regionDropdowns,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleClose = () => {
		setIsModalOpen((prev) => !prev);
	};

	return (
		<>
			<nav
				className={clsx(styles.navCorporate, { [styles.open]: openMobileMenu })}
			>
				<div className={styles.navCorporateHeader}>
					<HeaderLogo
						url={builderMenuContent.logo.url}
						altText={builderMenuContent.logo.altText}
					/>
					<HeaderBurgerButton
						isOpen
						ariaLabel='Close main navigation menu'
						onButtonClick={() => setOpenMobileMenu(false)}
					/>
				</div>
				<div className={styles.modalContainer}></div>
				<div className={styles.navItems}>
					<span
						className={styles.navItem}
						onClick={() => setIsModalOpen((prev) => !prev)}
					>
						Order Online
					</span>
					<span className={styles.regionSelector}>
						<RegionWebsiteSelectorModal
							onMobile={true}
							regionDropdowns={regionDropdowns}
						/>
					</span>
					{/* Removed ...defaultNavigationOptions from this map as not currently used */}
					{[...builderMenuContent.menuItems].map(
						({ text, link, ariaLabel, subMenuItems }) => (
							<HeaderNavItems
								key={link}
								text={text}
								link={link}
								ariaLabel={ariaLabel}
								subMenuItems={subMenuItems}
								setOpenMobileMenu={setOpenMobileMenu}
							/>
						),
					)}
				</div>
			</nav>
			<Modal
				isOpen={isModalOpen}
				toggleModal={handleClose}
				contentClassName={styles.modalContent}
				modalBackground='dark'
			>
				<RegionWebsiteSelectorModal regionDropdowns={regionDropdowns} />
			</Modal>
		</>
	);
};

export default NavCorporate;
